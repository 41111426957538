.heading {
    background: #5861AE;
    color: #fff;
    font-size: 2rem;
    height: 6vh;
    padding: 2vh 2vw;
}

.subheading {
    height: 12.5vh;
    background-color: #F4F9FF;
}

.block_input {
    display: block;
    margin-top: 1rem;
    border: none;
}

.inline_hd {
    padding: 1rem 2rem;
    display: inline-block;
}

.inline_hd>input {
    color: #777;
}

.list_style {
    list-style-type: none;
}

.bottom_border {
    margin-right: 5vw;
    border-bottom: 1px solid #ddd;
}

.corner {
    float: right;
    position: absolute;
    bottom: 5%;
    right: 5%;
}

.total_block {
    margin-right: 5vw;
    border-bottom: 1px solid #ddd;
    float: right;
    padding: 1rem 8rem;
    color: #777;
}

.address {
    display: block;
    clear: both;
    padding-top: 2rem;
}

.homeAddress {
    border: 1.5px solid #5861AE;
    padding: 5px;
    margin: 10px 0;
    width: 15vw;
    box-shadow: 1px 1px 5px gray;
}