body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 10px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 101;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  width: 286px;
  height: 324px;
  z-index: 101;
}

.close-modal {
  position: absolute;
  width: 175px;
  height: 44px;
  top: 280px;
  right: 82px;
  padding: 5px 7px;
  border-radius: 22px 22px 22px 22px;
  border: 1px solid black;
}

.close-modal:hover {
  background-color: #5861ae;
  color: #f1f1f1;
  border-style: none;
}

.tickimage {
  width: 80px;
  height: 80px;
  margin: 30px 50px 0px 100px;
}

.modal_title {
  width: 297px;
  height: 66px;
  margin-left: 37px;
}

.modal_para {
  width: 263px;
  height: 45px;
  text-align: center;
  font: normal normal normal 17px/22px Open Sans;
  letter-spacing: -0.41px;
  color: #0a1f44;
  opacity: 1;
  font-size: 17px;
  margin: -35px 0px 0px 10px;
}
