.header {
  width: 100%;
  height: 10vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 48px #0000000f;
  font-size: 20px;
  position: relative;
}

.header_title {
  position: absolute;
  padding: 1rem 5rem;
  font: normal normal 900 20px/27px Avenir;
  letter-spacing: 1.8px;
  color: #5861ae;
}

.nav_lists {
  margin: 0;
  list-style: none;
  display: flex;
  float: right;
  height: 10vh;
  font-size: 14px;
}
.nav_lists__links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  font: normal normal normal 14px/19px Avenir;
  color: #777;
  border-left: 1px solid rgb(230, 228, 228);
  cursor: pointer;
}

.nav_lists__links:hover {
  background-color: #5861ae;
  color: white;
}

.nav_lists__links:active {
  background-color: #5861ae;
  color: white;
}

.color {
  background-color: #5861ae;
  color: #ffffff;
}

.invalid {
  color: red;
}
