#float-label {
  display: flex;
  flex-direction: column;
  min-width: 40vw;
}

#float-label input {
  width: 80%;
  height: 60px;
  padding: 14px 16px 0 10px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border-bottom: 1px solid #77838f;
  border-style: none;
  text-decoration: none;
}

#float-label label {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 12px;
  color: #999;
  pointer-events: none;
}
#float-label {
  position: relative;
}
#float-label label {
  position: absolute;
  transform: translate(0, 12px) scale(1);
}
#float-label label {
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

#float-label:focus-within label {
  transform: translate(0, 12px) scale(0.75);
}

.container {
  width: 100vw;
  display: flex;
}

.section-1 {
  width: 50vw;
  padding-left: 10rem;
  box-sizing: border-box;
  overflow-x: scroll;
}

.section1_title {
  font: normal normal 700 100px/120px Avenir;
  color: #5861ae;
}

.section1_para {
  font: normal normal 20px/26px Avenir;
  letter-spacing: 0.8px;
  color: #565657;
}

.section1_para1 {
  font: normal normal normal 16px/26px Avenir;
  letter-spacing: 0.64px;
  color: #565657;
  font-size: 13px;
}

.section1_btn {
  width: 150px;
  height: 50px;
  margin-bottom: 20px;
  border: 2px solid #4552c1;
  border-radius: 4px;
  background-color: white;
}

.section1_btn:hover, .section1_btn:active {
  background-color: #4552c1;
  color: white;
  box-shadow: 2px 2px 10px gray;
  transform: scale(1.01);
}

.section-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50vw;
  background: #b9c0fd1a 0% 0% no-repeat padding-box;
}

.section2_title {
  color: #4552c1;
  margin-left: 130px;
  font: normal normal 32px/60px Avenir;
  letter-spacing: 1.28px;
  color: #4552c1;
}

.section2_form {
  margin: 0 15%;
  color: #77838f;
  font-size: 14px;
}

#float-label {
  font-size: 14px;
  margin-bottom: 8px;
}

.section2_forget {
  font: normal normal 12px/26px Avenir;
  letter-spacing: 0.64px;
  color: #4552c1;
  text-align: right;
  margin-right: 3rem;
}

.section2_btn {
  width: 150px;
  height: 50px;
  background: #4552c1 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #fff;
  border-style: none;
  margin-bottom: 20px;
}
.section2_btn:hover {
  color: white;
  box-shadow: 2px 2px 10px gray;
  transform: scale(1.01);
}

.section2_error_message {
  color: red;
  font-size: 13px;
}

@media only screen and (max-width: 500px) {
  .container {
    display: block;
  }
  .section-1 {
    padding: 0;
    text-align: center;
    width: 100vw;
  }
  .section1_title {
    font-size: 80px;
    text-align: center;
  }
  .section-2 {
    width: 100vw;
  }
  .section2_form {
    margin: 0;
    margin-left: 10vw;
  }
}