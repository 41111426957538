.footer2 {
  display: grid;
  width: 100vw;
  grid-template-columns: repeat(4, 25vw);
}

.footer2_title {
  text-align: center;
  font: normal normal 18px/24px Avenir;
  letter-spacing: 0.18px;
  color: #182838;
}
.footer2_para {
  text-align: center;
  font: normal normal normal 15px/20px Avenir;
  letter-spacing: 0.6px;
  color: #3d3d43;
}

.footer2_sec2 {
  text-align: left;
  font: normal normal 16px/24px Avenir;
  letter-spacing: 0.16px;
  color: #182838;
}

.footer2_first__ul {
  list-style: none;
  margin: 20px;
}
.footer2_first__ul li {
  padding: 10px;
}

.footer2_sec3 {
  margin-top: 10px;
}

.footer2_socialmedia {
  margin-bottom: 20px;
  display: flex;
  gap: 30px;
}
.footer2_socialmedia__icons {
  width: 40px;
}
